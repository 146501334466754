import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Container, Row, Column } from '../Grid';
import Video from '../Video';
import Action from '../Resource/Action';
import Image from '../Image';
import Resource from '../Resource';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const EpisodePlayer = ({ episodes, playerLabel, intro, className }) => {
  const [activeIndex, setActiveIndex] = useState(0); // zero-based index
  const viewerRef = useRef(null);
  const { t } = useTranslation(['video-topic-series', 'common']);

  const renderEpisodes = () => (
    <>
      {episodes.map((episode, index) => (
        <button
          key={index}
          className="episode-player__episode"
          onClick={() => {
            setActiveIndex(index);
            if (viewerRef.current.getBoundingClientRect()['top'] < 0) {
              viewerRef.current.scrollIntoView({ behavior: 'smooth' });
            }
          }}
        >
          <div
            className={classNames(
              index === activeIndex && 'episode-player__episode--active'
            )}
          >
            <Image filename={episode.posterSrc} className="episode__image" />
          </div>
          <div>
            <h5>{t(episode.title)}</h5>
            <p className="episode-player__thumbnail-description">
              {t(episode.description)}
            </p>
          </div>
        </button>
      ))}
    </>
  );

  const renderCurrentVideo = () => {
    const episode = episodes[activeIndex];
    return (
      <Video
        guidSrc={
          t(episode.azureSrc) === episode.azureSrc
            ? episode.azureSrc
            : t(episode.azureSrc)
        }
        poster={episode.posterSrc}
        className="video-player"
        title={t(episode.title)}
      />
    );
  };

  const renderCurrentData = () => {
    const episode = episodes[activeIndex];
    return (
      <Container fullWidth className="pl-0">
        <Row>
          <Column large={7}>
            <div className="episode-player__intro pb-2">
              <h3>{t(`${episode.descriptionTitle}`)}</h3>
              <p>{t(`${episode.description}`)}</p>
              {episode.actions && (
                <div className="episode-player__actions">
                  {episode.actions.map((action, i) => (
                    <Action
                      key={i}
                      download={
                        t(action.path) === action.path
                          ? action.path
                          : t(action.path)
                      }
                      label={t(action.label)}
                    />
                  ))}
                </div>
              )}
              {intro}
            </div>
          </Column>
          <Column large={4}>
            <Resource
              tophat={t('common:educatorGuide')}
              img="img-video-topic-ed-guide.jpg"
              title={t('video-topic-series:resourceHeader')}
              description={t('video-topic-series:resourceText')}
              actions={[
                {
                  label: t('common:educatorGuide'),
                  download: t('vtsEdGuidePDF'),
                  metadata: 'PDF 510 KB',
                  linkFromImg: true,
                },
              ]}
            />
          </Column>
        </Row>
      </Container>
    );
  };

  return (
    <Container className={`episode-player ${className}`}>
      <h2>{playerLabel}</h2>
      <div className="episode-player__content-wrapper">
        <div ref={viewerRef} className="episode-player__video-wrapper">
          <div className="episode-player__video">{renderCurrentVideo()}</div>
          <div className="episode-player__video-desktop-spacer"></div>
        </div>
        <div className="episode-player__current-data">
          {renderCurrentData()}
        </div>
        <div className="episode-player__episode-wrapper">
          <div className="episode-player__episode-desktop-spacer"></div>
          <div className="episode-player__episodes">{renderEpisodes()}</div>
        </div>
      </div>
    </Container>
  );
};

EpisodePlayer.propTypes = {
  episodes: PropTypes.array.isRequired,
  intro: PropTypes.element,
  playerLabel: PropTypes.string,
  className: PropTypes.string,
};

EpisodePlayer.defaultProps = {
  playerLabel: '',
  className: '',
};

export default EpisodePlayer;
