import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import Button from '../components/Button';
import Icon from '../components/Icon';
import CareerCallout from './partials/_CareerCallout';
import EpisodePlayer from '../components/EpisodePlayer';
import { useTranslation } from 'react-i18next';

const VideoTopicPage = ({ data }) => {
  const { t } = useTranslation(['video-topic-series', 'common']);

  return (
    <Layout title="Video Topic Series">
      <Section className="section-bg">
        <Container>
          <Row>
            <Column>
              <h1 className="hero__heading">{t('videoTopicSeriesHeader')}</h1>
            </Column>
          </Row>
          <Row>
            <Column size={8}>
              <p>
                {t('videoTopicSeriesText')}
                <Button
                  link
                  target="_blank"
                  rel="noopener noreferrer"
                  to={t('vtsEdGuidePDF')}
                >
                  <Icon marginLeft marginRight name="download" />
                  {t('common:educatorGuide')}
                </Button>
              </p>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container fullWidth>
          <Row>
            <EpisodePlayer
              className="pt-0"
              episodes={data.allVideoTopicsYaml.edges.map((edge) => edge.node)}
              intro={
                <>
                  <h3 className="pt-3">{t('episodePlayerHeading')}</h3>
                  <p>{t('episodePlayerText1')}</p>
                  <p>{t('episodePlayerText2')}</p>
                </>
              }
            />
          </Row>
        </Container>
      </Section>
      <CareerCallout
        title={t('common:vulnerabilityAnalyst')}
        description={
          <p
            dangerouslySetInnerHTML={{
              __html: t('common:careerVulnerabilityAnalystText'),
            }}
          />
        }
        path="/career-profiles/vulnerability-analyst"
        img={'career-profiles/career-profiles-jazmine-headshot.jpg'}
        name="Jazmin Torres"
        job="Vulnerability Analyst, Mastercard"
      />
    </Layout>
  );
};

export const query = graphql`
  query VideoTopicsQuery {
    allVideoTopicsYaml {
      edges {
        node {
          id
          actions {
            label
            path
            type
          }
          azureSrc
          posterSrc
          descriptionTitle
          description
          episodeDesc
          title
        }
      }
    }
  }
`;

export default VideoTopicPage;
