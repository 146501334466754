import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import Button from '../Button';

import './styles.scss';

const Action = ({
  label,
  metadata,
  module,
  moduleName,
  download,
  noIcon,
  asLink,
}) => {
  const renderModuleLink = () => (
    <Link
      to={module}
      className="resource__action-link"
      data-click-type="Module Launch"
      data-click-name={moduleName}
    >
      {!noIcon && <Icon name="module" className="resource__action-icon" />}
      {label}
    </Link>
  );

  const renderDownload = () => (
    <Button
      to={download}
      target="_blank"
      rel="noopener noreferrer"
      className="resource__action-link"
      link={asLink}
    >
      {!noIcon && (
        <Icon name="download" className="resource__action-icon" marginRight />
      )}
      {label}
    </Button>
  );

  return (
    <div className="resource__action">
      {module && renderModuleLink()}
      {download && renderDownload()}
      {metadata && <span className="resource__metadata">{metadata}</span>}
    </div>
  );
};

Action.propTypes = {
  label: PropTypes.node.isRequired,
  metadata: PropTypes.string,
  module: PropTypes.string,
  download: PropTypes.string,
  noIcon: PropTypes.bool,
  asLink: PropTypes.bool,
};

export default Action;
